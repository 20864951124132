<template>
    <section id="clients">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">{{ $t('body_type_page') }}</h2>
                </div>
            </div>

            <div v-if="body_type != null">{{ $t(body_type.name) }}</div>

            <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit" v-on:click="forwardToPreferences()">{{ $t('select_nutrition_preferences') }}</button>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'EvaluationTestResult',
        data() {
            return {
                body_type: null,
            }
        },
        methods: {
            async evaluateBodyChecks(checked_ids) {
                if(checked_ids == null) {
                    this.$router.push({
                        name: "evaluation",
                        params: {}
                    });
                    return
                }
                let id_param_string = checked_ids.map(String).join('&checkedIDs=')

                try {
                    let response = await this.$http.get(
                        process.env.VUE_APP_AI_TEA_TECH_BACKEND_HOST + "body_type?checkedIDs=" + id_param_string,
                    );
                    this.body_type = response.data;
                } catch (error) {
                    console.log(error);
                }
            },
            async forwardToPreferences() {
                this.$router.push({
                    name: "preferences",
                    params: { 'body_type_id': this.body_type.id }
                });
            }
        },
        created() {
            let checked_ids = this.$route.params.checked_ids;
            this.evaluateBodyChecks(checked_ids);
        }
    };
</script>
